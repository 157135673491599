import React, { useCallback } from "react"
import styles from "./SegmentCustomersPreview.module.scss"
import { useParams } from "react-router"
import { SEGMENT } from "sharedConstants"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHasAccess } from "resources/user/currentUserQueries"
import { useFetchSegmentCustomers } from "resources/segment/segment/segmentQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"

type SegmentCustomersPreviewProps = {
  hasUnsavedConditions: boolean
  customersListOffset: number
  setCustomersListOffset: (offset: number | ((offset: number) => number)) => void
  isOpen: boolean
}

const MAX_OFFSET = 300

export default function SegmentCustomersPreview({
  hasUnsavedConditions,
  customersListOffset,
  setCustomersListOffset,
  isOpen,
}: SegmentCustomersPreviewProps) {
  const { id } = useParams<{ id: string }>()

  const hasAccess = useHasAccess()

  const canSeeCustomers = hasAccess.customers.detail
  const {
    error,
    data: segmentCustomers,
    isFetching: isLoadingCustomers,
    fetchNextPage: loadMoreCustomers,
  } = useFetchSegmentCustomers(+id, { enabled: canSeeCustomers })

  const goToPrevPage = useCallback(() => {
    setCustomersListOffset(s => s - SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
  }, [setCustomersListOffset])

  const goToNextPage = useCallback(() => {
    if (
      segmentCustomers!.length <= customersListOffset + 2 * SEGMENT.CUSTOMER.ITEMS_PER_PAGE &&
      customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE <= MAX_OFFSET
    ) {
      loadMoreCustomers()
    }

    setCustomersListOffset(s => s + SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
  }, [customersListOffset, loadMoreCustomers, segmentCustomers, setCustomersListOffset])

  const { setIndex, setSegmentId } = useProfileIteratorStore()

  if (!isOpen) {
    return null
  }

  return (
    <div className={styles.container}>
      {!hasAccess.customers.detail ? (
        <div className={styles.message}>You don't have permission to view customers.</div>
      ) : hasUnsavedConditions ? (
        <div className={styles.message}>Save segment conditions to see the customers preview.</div>
      ) : isLoadingCustomers ? (
        <LoadingIndicator />
      ) : error ? (
        <div className={styles.message}>Error while loading.</div>
      ) : segmentCustomers?.length === 0 ? (
        <div className={styles.message}>No profiles found.</div>
      ) : (
        <>
          {segmentCustomers
            ?.slice(customersListOffset, customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE)
            .map((customer, index) => (
              <div data-testid="profile" className={styles.customerRow} key={customer.id}>
                <Link
                  className={styles.customerLink}
                  onClick={() => {
                    setIndex(index + customersListOffset)
                    setSegmentId(+id)
                  }}
                  to={{
                    pathname: getRoutePath("profiles.detail", { id: customer.id }),
                    state: { goBack: true, prev: "segment", segmentId: id },
                  }}
                >
                  {customer.id}
                </Link>
              </div>
            ))}
          <div className={styles.customersListFooter}>
            <Button
              color="grey"
              disabled={customersListOffset === 0}
              variant="outlined"
              onClick={goToPrevPage}
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </Button>
            <div className={styles.pageNumber}>
              Page {customersListOffset / SEGMENT.CUSTOMER.ITEMS_PER_PAGE + 1}
            </div>
            <Button
              color="grey"
              disabled={
                (segmentCustomers?.length ?? 0) <
                  customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE ||
                customersListOffset + SEGMENT.CUSTOMER.ITEMS_PER_PAGE > MAX_OFFSET
              }
              variant="outlined"
              onClick={goToNextPage}
            >
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
