import IconButton from "components/UI/elements/IconButton/IconButton"
import { PERMISSION } from "sharedConstants"
import { TrashItem, TrashItemType } from "types/trash"
import getReadableItemType from "pages/Trash/utilities/getReadableItemType"
import { useHasAccess, useHasSegmentPermission } from "resources/user/currentUserQueries"
import Table, { Column, Modified, Name } from "components/Table/Table"

type TrashDataTableProps = {
  data: TrashItem[]
  hasMoreDataToLoad: boolean
  itemType: TrashItemType
  onRestoreClick: (trashItem: TrashItem) => void
  onLoadMore: () => Promise<any>
  isLoadingMore: boolean
}

export default function TrashDataTable({
  data,
  hasMoreDataToLoad,
  itemType,
  onRestoreClick,
  onLoadMore,
  isLoadingMore,
}: TrashDataTableProps) {
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()

  function isAllowedToRestore(item: TrashItem) {
    switch (item.type) {
      case "custom_segments":
      case "lookalike_segments": {
        return (
          hasAccess.segments.editForeign ||
          hasSegmentPermission(item.id as number, { segmentType: "custom" }) === PERMISSION.WRITE
        )
      }
      case "featured_segments": {
        return (
          hasAccess.segments.featured.edit ||
          hasSegmentPermission(item.id as number, { segmentType: "featured" }) === PERMISSION.WRITE
        )
      }
      case "smart_segments": {
        return (
          hasAccess.setup.smartSegments ||
          hasSegmentPermission(item.id as number, { segmentType: "smart" }) === PERMISSION.WRITE
        )
      }
      case "popup_web_banners": {
        return hasAccess.webBanners.edit
      }
      case "embedded_web_banners": {
        return hasAccess.webBanners.edit
      }
      case "push_notifications": {
        return hasAccess.mobilePushNotifications.edit
      }
      case "emails": {
        return hasAccess.emails.edit
      }
      case "segment_export_destinations": {
        return hasAccess.setup.implementation
      }
      case "users": {
        return hasAccess.administration.users
      }
      case "user_roles": {
        return hasAccess.administration.users
      }
      case "funnel_groups": {
        return hasAccess.setup.funnelGroups
      }
    }
  }

  const columns: Column<TrashItem>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "1fr",
      renderCell: item => (
        <div data-testid="td-name">
          <Name name={item.name} />
        </div>
      ),
    },
    {
      id: "deleted",
      label: "Deleted at",
      gridTemplate: "max-content",
      renderCell: item => <Modified modifiedAt={item.deleted_at} modifiedBy={item.deleted_by} />,
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: item => (
        <IconButton
          color="green"
          size="xs"
          icon={["far", "redo"]}
          onClick={() => onRestoreClick(item)}
          data-testid="restore-trash-item"
          tooltip="Restore"
          variant="outlined"
          disabled={!isAllowedToRestore(item)}
        />
      ),
    },
  ]

  return (
    <Table
      emptyMessage={`No ${getReadableItemType(itemType, {
        capitalize: false,
        plural: true,
      })} found.`}
      columns={columns}
      data={data}
      fetchNextPage={onLoadMore}
      hasNextPage={hasMoreDataToLoad}
      isFetchingNextPage={isLoadingMore}
    />
  )
}
