import requestFactory from "api/request"
import {
  FunnelGroup,
  FunnelGroupCreatePayload,
  FunnelGroupListDeletedResponse,
  FunnelGroupListResponse,
  FunnelGroupModifyPayload,
  FunnelGroupMovePayload,
} from "./funnelGroupTypes"

const funnelGroup = {
  listAll(): Promise<FunnelGroupListResponse> {
    return requestFactory("get", "/funnel_groups")
  },

  create(payload: FunnelGroupCreatePayload): Promise<{ funnel_group: FunnelGroup }> {
    return requestFactory("post", "/funnel_groups", payload)
  },

  modify(
    id: FunnelGroup["id"],
    payload: FunnelGroupModifyPayload,
  ): Promise<{ funnel_group: FunnelGroup }> {
    return requestFactory("patch", `/funnel_groups/${id}`, payload)
  },

  delete(id: FunnelGroup["id"]): Promise<void> {
    return requestFactory("delete", `/funnel_groups/${id}`)
  },

  move(id: FunnelGroup["id"], payload: FunnelGroupMovePayload): Promise<void> {
    return requestFactory("patch", `/funnel_groups/${id}/move`, payload)
  },

  listDeleted(): Promise<FunnelGroupListDeletedResponse> {
    return requestFactory("get", "/funnel_groups/trash")
  },

  restoreDeleted(id: FunnelGroup["id"]): Promise<{ funnel_group: FunnelGroup }> {
    return requestFactory("patch", `/funnel_groups/${id}/trash`)
  },
}

export default funnelGroup
